import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, Polygon, DirectionsRenderer } from '@react-google-maps/api';

const containerStyle = {
  width: '100vw', // Full screen width
  height: '100vh', // Full screen height
};

const center = {
  lat: 36.752887, // Example: Latitude of Algiers
  lng: 3.042048,  // Example: Longitude of Algiers
};

function Map() {
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const [ids, setIds] = useState([]);
  const [shapes, setShapes] = useState({});
  const [data, setData] = useState({});
  const [directions, setDirections] = useState(null); // State for directions

  const toggleIds = (id) => {
    if (ids.includes(id)) {
      setIds(ids.filter((i) => i !== id));
    } else {
      setIds([...ids, id]);
    }
  };

  const getData = async () => {
    const authToken = sessionStorage.getItem("authToken");
    const url = `https://api.geo-dz.com/api/data`;
    const d = { authToken, ids };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(d)
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setData(data);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (ids.length > 0) {
      getData();
    }
  }, [ids]);

  const getShapes = async (category) => {
    const authToken = sessionStorage.getItem("authToken");
    const url = `https://api.geo-dz.com/api/shapes?category_id=${category}&authToken=${authToken}`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setShapes(data.shapes || {});
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (selectedCategory) getShapes(selectedCategory);
  }, [selectedCategory]);

  const getCategories = async () => {
    const authToken = sessionStorage.getItem("authToken");
    const url = `https://api.geo-dz.com/api/categories?authToken=${authToken}`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setCategories(data.categories || {});
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getDirections = (coordinates) => {
    const directionsService = new window.google.maps.DirectionsService();
    const origin = coordinates[0];
    const destination = coordinates[coordinates.length - 1];
    const waypoints = coordinates.slice(1, -1).map(coord => ({ location: coord }));

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`Error fetching directions ${result}`);
        }
      }
    );
  };

  useEffect(() => {
    if (data.selectedShapes) {
      const polylineShape = data.selectedShapes.find(shape => shape.type === 2);
      if (polylineShape) {
        const coordinates = JSON.parse(polylineShape.position).map(pos => ({ lat: pos.lat, lng: pos.lng }));
        getDirections(coordinates);
      }
    }
  }, [data]);

  return (
    <div className="map-container">
      <div className="map-header">
        {Object.keys(categories).length > 0 ? (
          Object.entries(categories).map(([id, name]) => (
            <div key={id} 
                 onClick={() => setSelectedCategory(id)}
                 style={{ backgroundColor: selectedCategory === id ? '#ccc' : '#f1f1f1' }}
                 className="map-category-button">
              {name}
            </div>
          ))
        ) : (
          <p>Loading categories...</p>
        )}
      </div>

      {shapes && Object.keys(shapes).length > 0 && (
        <div className="shapes-container">
          {Object.entries(shapes).map(([id, shape]) => (
            <div 
              key={id}
              onClick={() => toggleIds(shape.id)}
              style={{ backgroundColor: ids.includes(shape.id) ? '#ccc' : '#f1f1f1' }}
              className="shape">
              <img 
                style={{ width: '50px', height: '30px', objectFit: 'contain' }}
                src={`https://api.geo-dz.com/public/icons/${shape.id}.png`} 
                alt={shape.name} 
              />
              <p>{shape.name}</p>
            </div>
          ))}
        </div>
      )}

      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {/* Render Markers for type 1 */}
        {data.selectedShapes && data.selectedShapes
          .filter(shape => shape.type === 1)
          .map((shape, index) => {
            const position = JSON.parse(shape.position);
            return <Marker key={index} position={position} />
          })
        }

        {/* Render Polygons for type 3 */}
        {data.selectedShapes && data.selectedShapes
          .filter(shape => shape.type === 3)
          .map((shape, index) => {
            const path = JSON.parse(shape.position).map(pos => ({ lat: pos.lat, lng: pos.lng }));
            return <Polygon key={index} paths={path} />;
          })
        }

        {/* Render Directions for type 2 (Polyline) */}
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </div>
  );
}

export default Map;
