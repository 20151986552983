import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const StickyNavigation = () => {
  const [navBg, setNavBg] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setNavBg(true);
      } else {
        setNavBg(false);
      }
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);


  return (
     <section 
     data-aos="fade-up"
     data-aos-duration="1000"
     data-aos-delay="100"
     className={`fixed-top navigation ${navBg ? 'nav-bg' : ''}`}>
  <div className="container">
    <nav className="navbar navbar-expand-lg navbar-light">
      <a className="navbar-brand" 

      href="index.html"><img 
      style = {{
        width: "10rem",
        cursor: "pointer"
      }}
      src="images/logo.svg" alt="logo"/></a>
      <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
        aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse text-center" id="navbar">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="index.html">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#feature">Feature</a>
          </li>

          <li className="nav-item">
            <a className="nav-link page-scroll" href="#pricing">Pricing</a>
          </li>

        </ul>
        <a
        onClick={() => {
          const authToken = sessionStorage.getItem("authToken");
          if (authToken) {
            navigate('/dashboard');
          } else {
          navigate('/login');
          }
        }
        }
        className="btn btn-primary ml-lg-3 primary-shadow">

        {sessionStorage.getItem("authToken") ? "Dashboard" : "Login"}
        </a>
      </div>
    </nav>
  </div>
</section>
  );
};

export default StickyNavigation;
