import logo from '../logo.svg';
// bootstrap

// AOS
import Slider from "react-slick";
import React, { useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import * as THREE from 'three';

const GlobeCanvas = () => {
  const containerRef = useRef(null);
  const sphereRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    // Setup Scene
    const scene = new THREE.Scene();

    // Setup Renderer
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(containerRef.current.offsetHeight, containerRef.current.offsetHeight);
    renderer.setClearColor(0x000000, 0);
    containerRef.current.appendChild(renderer.domElement);

    // Setup Camera
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    camera.position.z = 6;

    // Lights
    const light1 = new THREE.PointLight(0x5a54ff, 0.75);
    light1.position.set(-150, 150, -50);

    const light2 = new THREE.PointLight(0x4158f6, 0.75);
    light2.position.set(-400, 200, 150);

    const light3 = new THREE.PointLight(0x803bff, 0.7);
    light3.position.set(100, 250, -100);

    scene.add(light1, light2, light3);

    // Setup Globe
    const sphereGeometry = new THREE.SphereGeometry(2, 64, 64);
    const sphereMaterial = new THREE.MeshLambertMaterial({ color: 0xeeeeee });
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphere.castShadow = true;
    sphere.receiveShadow = true;
    scene.add(sphere);

    // Map Overlay
    const loader = new THREE.TextureLoader();
    const overlayMaterial = new THREE.MeshBasicMaterial({
      map: loader.load("https://i.imgur.com/JLFp6Ws.png"),
      transparent: true,
    });
    const overlaySphereGeometry = new THREE.SphereGeometry(2.003, 64, 64);
    const overlaySphere = new THREE.Mesh(overlaySphereGeometry, overlayMaterial);
    sphere.add(overlaySphere);

    // Setup Animation Loop
    let isDragging = false;
    let previousMousePosition = { x: 0, y: 0 };

    const handleMouseDown = () => {
      isDragging = true;
    };

    const handleMouseMove = (e) => {
      const deltaMove = { x: e.offsetX - previousMousePosition.x };
      if (isDragging) {
        sphere.rotation.y += deltaMove.x * 0.004;
      }
      previousMousePosition = { x: e.offsetX, y: e.offsetY };
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    const animate = () => {
      requestAnimationFrame(animate);
      if (!isDragging) {
        sphere.rotation.y += 0.0005;
      }
      renderer.render(scene, camera);
    };

    animate();

    // Event listeners
    containerRef.current.addEventListener("mousedown", handleMouseDown);
    containerRef.current.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    // Cleanup
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("mousedown", handleMouseDown);
        containerRef.current.removeEventListener("mousemove", handleMouseMove);
      }
      window.removeEventListener("mouseup", handleMouseUp);
      // Clean up renderer and scene
      renderer.dispose();
      scene.clear();
    };
  }, []);

  return <div id="globeCanvas" ref={containerRef} className="globeCanvas"></div>;
};
function App() {

  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  
  return (
<div 

className="app2">
 


<section 
 data-aos="fade-up"
 data-aos-duration="1000"
 data-aos-delay="100"
className="hero-section hero" data-background="" 
style = {{

}}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center zindex-1">

    
        <h1 className="mb-3">
          Get real-time data
          <br/>
          In an interactive map
          </h1>
        <p className="mb-4">
          It's hard to find industrial, commercial, and residential data in one place, especially in Algeria<br/>
          We provide you with the most accurate and up-to-date data in the country
          
          </p>
          
        <a 
        onClick={() => {
          navigate('/login');
        }}
        className="btn btn-secondary btn-lg">Join</a>
      </div>
    </div>
  </div>



  <div id="scene">
    <img className="img-fluid hero-bg-1 up-down-animation" src="/images/background-shape/feature-bg-2.png" alt=""/>
    <img className="img-fluid hero-bg-2 left-right-animation" src="/images/background-shape/seo-ball-1.png" alt=""/>
    <img className="img-fluid hero-bg-3 left-right-animation" src="/images/background-shape/seo-half-cycle.png" alt=""/>
    <img className="img-fluid hero-bg-4 up-down-animation" src="/images/background-shape/green-dot.png" alt=""/>
    <img className="img-fluid hero-bg-5 left-right-animation" src="/images/background-shape/blue-half-cycle.png" alt=""/>
    <img className="img-fluid hero-bg-6 up-down-animation" src="/images/background-shape/seo-ball-1.png" alt=""/>
    <img className="img-fluid hero-bg-7 left-right-animation" src="/images/background-shape/yellow-triangle.png" alt=""/>
    <img className="img-fluid hero-bg-8 up-down-animation" src="/images/background-shape/service-half-cycle.png" alt=""/>
    <img className="img-fluid hero-bg-9 up-down-animation" src="/images/background-shape/team-bg-triangle.png" alt=""/>
  </div>
</section>

<section 
 data-aos="fade-up"
 data-aos-duration="1000"
 data-aos-delay="100"
className="section feature mb-0" id="feature">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <h2 className="section-title">Awesome Features</h2>
        <p className="mb-100">
          You have many categories to deal with
          .<br/>Industrial, Medical... etc</p>
      </div>
      <div className="col-md-6 mb-80">
        <div className="d-flex feature-item">
          <div>
            <i className="ti-ruler-pencil feature-icon mr-4"></i>
          </div>
          <div>
              <p>An easy tool to use</p>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-80">
        <div className="d-flex feature-item">
          <div>
            <i className="ti-layout-cta-left feature-icon mr-4"></i>
          </div>
          <div>
            <p>Adaptative and interactive</p>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-80">
        <div className="d-flex feature-item">
          <div>
            <i className="ti-split-v-alt feature-icon mr-4"></i>
          </div>
          <div>
            <h4></h4>
            <p>Use the API</p>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-80">
        <div className="d-flex feature-item">
          <div>
            <i className="ti-layers-alt feature-icon mr-4"></i>
          </div>
          <div>
            <p>Organized Layouts</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img className="feature-bg-1 up-down-animation" src="/images/background-shape/feature-bg-1.png" alt="bg-shape"/>
  <img className="feature-bg-2 left-right-animation" src="/images/background-shape/feature-bg-2.png" alt="bg-shape"/>
</section>

<section className="section-lg seo">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="seo-image">
          <img className="img-fluid" src="/images/marketing/marketing.png" alt="form-img"/>
        </div>
      </div>
      <div className="col-md-5">
        <h2 className="section-title">A Complete Range Geo Data</h2>
        <p>Algeria is known for its huge size and its diversity in terms of industries and services. We provide you with the most accurate and up-to-date data in the country.
        </p>
      </div>
    </div>
  </div>
  <img className="img-fluid seo-bg" src="/images/backgrounds/seo-bg.png" alt="seo-bg"/>
  <img className="seo-bg-shape-1 left-right-animation" src="/images/background-shape/seo-ball-1.png" alt="bg-shape"/>
  <img className="seo-bg-shape-2 up-down-animation" src="/images/background-shape/seo-half-cycle.png" alt="bg-shape"/>
  <img className="seo-bg-shape-3 left-right-animation" src="/images/background-shape/seo-ball-2.png" alt="bg-shape"/>
</section>

<section className="section-lg service">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-md-5 order-2 order-md-1">
        <h2 className="section-title">Use the API</h2>
        <p className="mb-4">
          You can use our API to get the data you need in your application

        </p>
        <ul className="pl-0 service-list">
          <li><i className="ti-layout-tab-window text-purple"></i>Highly customizable</li>
          <li><i className="ti-layout-placeholder text-purple"></i>Very easy to customize</li>
          <li><i className="ti-support text-purple"></i>Effective support</li>
        </ul>
      </div>
      <div className="col-md-7 order-1 order-md-2">
        <img className="img-fluid layer-3" src="/images/service/service.png" alt="service"/>
      </div>
    </div>
  </div>
  <img className="img-fluid service-bg" src="/images/backgrounds/service-bg.png" alt="service-bg"/>
  <img className="service-bg-shape-1 up-down-animation" src="/images/background-shape/service-half-cycle.png" alt="background-shape"/>
  <img className="service-bg-shape-2 left-right-animation" src="/images/background-shape/feature-bg-2.png" alt="background-shape"/>
</section>



<section className="section-lg pb-0 pricing" id="pricing">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <h2 className="section-title">Our Pricing</h2>
        <p className="mb-50">Pay soon with EDAHABIA and CIB cards </p>
      </div>
      <div className="col-lg-10 mx-auto">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4 mb-5 mb-lg-0">
            <div className="rounded text-center pricing-table table-1">
              <h3>Free</h3>
              <h1>0<span>DA</span></h1>
              <p>Free plan for small teams or solopreneurs that want to get started with GeoDz.
              </p>
              <a href="#" className="btn pricing-btn px-2">Get Started</a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-5 mb-lg-0">
            <div className="rounded text-center pricing-table table-3">
              <h3>Premium</h3>
              <h1>1900<span>DA / Month</span></h1>
              <p> For businesses that want to take their data to the next level.
                Fully customizable and scalable.
              </p>
              <a href="#" className="btn pricing-btn px-2">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <img className="pricing-bg-shape-1 up-down-animation" src="/images/background-shape/seo-ball-1.png" alt="background-shape"/>
  <img className="pricing-bg-shape-2 up-down-animation" src="/images/background-shape/seo-half-cycle.png" alt="background-shape"/>
  <img className="pricing-bg-shape-3 left-right-animation" src="/images/background-shape/team-bg-triangle.png" alt="background-shape"/>
</section>




<footer className="footer-section footer"

style = {{
  backgroundImage: "url(/images/backgrounds/footer-bg.png)"
}}>
  <div className="container">
    <div className="row">
      <div className="col-lg-4 text-center text-lg-left mb-4 mb-lg-0">
        <a href="index.html">
          <img className="img-fluid" src="/images/logo.svg" alt="logo"/>
        </a>
      </div>
      <nav className="col-lg-8 align-self-center mb-5">
        <ul className="list-inline text-lg-right text-center footer-menu">
          <li className="list-inline-item active">contact@geo-dz.com</li>
          <li className="list-inline-item active"><a href="index.html">Home</a></li>
          <li className="list-inline-item"><a className="page-scroll" href="#feature">Feature</a></li>
          <li className="list-inline-item"><a className="page-scroll" href="#pricing">Pricing</a></li>
        </ul>
      </nav>
      <nav className="col-12">
        <ul className="list-inline text-lg-right text-center social-icon">
          <li className="list-inline-item">
            <a className="facebook" href="#"><i className="ti-facebook"></i></a>
          </li>
          <li className="list-inline-item">
            <a className="twitter" href="#"><i className="ti-twitter-alt"></i></a>
          </li>
          <li className="list-inline-item">
            <a className="linkedin" href="#"><i className="ti-linkedin"></i></a>
          </li>
          <li className="list-inline-item">
            <a className="black" href="#"><i className="ti-github"></i></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</footer>
  
    </div>
  );
}

export default App;
