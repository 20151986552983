import React, { useState, useEffect } from "react";
// navigate 
import { useNavigate } from 'react-router-dom';


const Logout = () => {
    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('username');
        // Add any additional logout logic here
    };

    const navigate = useNavigate();
    useEffect(() => {
        handleLogout();

        // Redirect to the home page
        window.location.href = "/";

    }, []);

    return (
        <br/>
    );
};

export default Logout;